<template>
  <b-row v-if="isDownloadable() === true">
    <b-overlay
      :show="
        $store.state.hearingStore.exportHearingLoading &&
        $store.state.hearingStore.currentExportDate === itemDate
      "
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="success"
        v-b-tooltip.hover
        title="Export PDF"
        :disabled="!itemDate"
        class="btn-sm"
        @click="exportHearing"
      >
        Export PDF
      </b-button>
    </b-overlay>
    <b-overlay
      :show="
        $store.state.hearingStore.exportEntireLoading &&
        $store.state.hearingStore.currentEntireDate === itemDate
      "
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      class="mx-1" 
      blur="2px"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        v-b-tooltip.hover
        title="Entire PDF"
        :disabled="!itemDate"
        class="btn-sm"
        @click="exportEntire"
      >
        Entire PDF
      </b-button>
    </b-overlay>
  </b-row>
</template>

<script>
import { BButton, VBTooltip, BOverlay, BRow } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import axios from 'axios'

export default {
  components: {
    BButton,
    BOverlay,
    BRow
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  props: {
    date: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'daily'
    }
  },
  data() {
    return {
      itemDate: this.date
    }
  },
  watch: {
    // Watch for changes to the date prop and update itemDate accordingly
    date(newDate) {
      this.itemDate = newDate
    }
  },
  methods: {
    isDownloadable() {
      if (this.type === 'archived') {
        return true
      }
      let date = this.itemDate
      let now = moment().format('DD-MM-YYYY')
      let nextDay = moment().add(1, 'days').format('DD-MM-YYYY')
      const currentTime = moment().format('HH')
      console.log(date, now, nextDay, currentTime)
      if (date === now) {
        return true
      } else if (date === nextDay) {
        if (currentTime >= 16) {
          return true
        }
      }
      return false
    },

    exportHearing() {
      let date = moment(this.itemDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.$store
        .dispatch('hearingStore/exportHearingByDate', { ctx: this, date: date })
        .then((response) => {
          console.log('Export', response)
          if (response !== null) {
            // var link = document.createElement('a')
            let href = response.url.replace(
              'http://s3.mymunshi.live',
              'https://app.mymunshi.in/exports'
            )
            this.downloadAs(href, `causelist-${date}.pdf`)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportEntire() {
      let date = moment(this.itemDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.$store
        .dispatch('hearingStore/exportEntireByDate', { ctx: this, date: date })
        .then((response) => {
          console.log('Entire', response)
          if (response !== null) {
            // var link = document.createElement('a')
            let href = response.url.replace(
              'http://s3.mymunshi.live',
              'https://app.mymunshi.in/exports'
            )
            this.downloadAs(href, `entire-causelist-${date}.pdf`)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    }
  }
}
</script>
